.imgplotboy {
    
    width: 300mm !important;
    /* height: 297mm !important; */
    margin: 0mm !important;
 }

 





 