.page-header {
  height: 70mm !important;
  position: fixed;
  top: 0mm;
  width: 100%;
}

.page-header-space {
  height: 70mm !important;
 }

.page-footer,
.page-footer-space {
  height: 74px;
}
.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.dataIN {
  vertical-align: bottom !important;
 
}

#footerImg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

#headerImg {
  max-width: 100%;
  max-height: 100%;
   object-fit: fill;
  width: 296mm;
  display: block;
}

.page {
  page-break-after: always;
}

@page {
  margin: 0mm;
  size: A4;
}

@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  .body {
    margin: 0;
  }
}
